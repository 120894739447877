<ng-container *ngIf="shouldShow()">
  <h2
    data-testid="message"
    class="w-75 flex-grow-1 text-center fs-5 m-0 p-0"
    [innerHTML]="text()"
  ></h2>
  <button
    class="position-absolute end-0 me-3"
    data-testid="button-close"
    type="button"
    icon="close"
    [etched]="{
      color: 'var(--fof-header-banner-button-color)',
      bgColor: 'var(--fof-header-banner-button-bg-color)',
      borderColor: 'var(--fof-header-banner-button-border-color)',
      colorOnHover: 'var(--fof-header-banner-button-hover-color)',
      bgColorOnHover: 'var(--fof-header-banner-button-hover-bg-color)',
      borderColorOnHover: 'var(--fof-header-banner-button-hover-border-color)'
    }"
    (click)="hasBanner.set(false)"
    title="-"
    i18n-title="@@header-banner.close"
  ></button>
</ng-container>
