import { BotIncomingMessage, BotTextIncomingMessage } from "../connector";
import { BotTextMessage, ConversationMessage, MessageMetadata } from "../../common/message.model";
import { BotIncomingMessageConverter } from "./message-converter.model";

/**
 * Converts a message used to suggest answers to the user.
 */
export class BotTextMessageConverter implements BotIncomingMessageConverter {
  supports(incomingMessage: BotIncomingMessage): boolean {
    return (
      incomingMessage?.content_type === "text" &&
      incomingMessage.content?.hasOwnProperty("value") &&
      !!(incomingMessage as BotTextIncomingMessage).content.value
    );
  }
  convert(incomingMessage: BotIncomingMessage): Array<ConversationMessage> {
    const botTextMessage = incomingMessage as BotTextIncomingMessage;
    return [
      BotTextMessage.makeMessage(
        botTextMessage.content.value,
        new MessageMetadata(botTextMessage.content.animation, "none", "default")
      ),
    ];
  }
}
