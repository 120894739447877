import { AppConfig } from "app/core";
import { MessagesScrollRequester } from "./messages-scroll.requester";
import { MessagesStore } from "../current/messages.store";
import { map } from "rxjs";
import { ScrollRequest } from "app/core/ui";

/**
 * Set up the automatic scrolling to messages in the current conversation block message
 * when new messages or blocks are received
 */
export function setupMessagesScroll(
  config: AppConfig,
  messagesStore: MessagesStore,
  messagesScrollRequester: MessagesScrollRequester
) {
  // no need to unsubscribe on destruction, as the subscription lives during the whole application lifecycle
  return () => {
    // handles the automatic scrolling on messages or on the current block
    if (config.scrollOnMessages) {
      messagesStore.message$
        .pipe(map((message) => new ScrollRequest(message.metadata.htmlId)))
        .subscribe(messagesScrollRequester);
    } else {
      messagesStore.currentBlock$
        .pipe(map((block) => new ScrollRequest(block.htmlId, block.temporary ? "end" : "start")))
        .subscribe(messagesScrollRequester);
    }
  };
}
