import { BotIncomingMessage, SatisfactionIncomingMessage } from "../connector";
import {
  ConversationMessage,
  SatisfactionCommentMessage,
  MessageMetadata,
} from "../../common/message.model";
import { BotIncomingMessageConverter } from "./message-converter.model";

/** Converts a message used to ask the user to evaluate his satisfaction t a free comment */
export class SatisfactionCommentMessageConverter implements BotIncomingMessageConverter {
  supports(incomingMessage: BotIncomingMessage): boolean {
    const satisfactionData = (incomingMessage as SatisfactionIncomingMessage).content?.extra
      ?.satisfaction;
    return (
      !!satisfactionData &&
      (!satisfactionData.useEmojiRatherThanComment ||
        !satisfactionData.emojis ||
        satisfactionData.emojis?.length === 0)
    );
  }
  convert(incomingMessage: BotIncomingMessage): Array<ConversationMessage> {
    const satisfactionData = (incomingMessage as SatisfactionIncomingMessage).content?.extra
      ?.satisfaction;
    return [
      new SatisfactionCommentMessage(
        satisfactionData.text,
        satisfactionData.redir,
        new MessageMetadata(undefined, "none", "default")
      ),
    ];
  }
}
