<ng-container *ngIf="data">
  <div class="d-flex evaluation-on-answer">
    <article class="me-1" [botMessage]="introductiveEvaluationBotMessage"></article>

    <button
      class="thumb-up btn btn-outine m-1"
      [specificColor]="'#008000'"
      [keepColored]="step === 'positive_evaluation'"
      [disabled]="isAnswered"
      (click)="onPositiveEvaluation()"
      data-testid="thumb-up"
    >
      <i aria-label="-" i18n-aria-label="@@icon.thumbs-up" class="fof-icon fof-icon-thumbs-up"></i>
    </button>
    <button
      class="thumb-down btn btn-outine m-1"
      [specificColor]="'#FF0000'"
      [keepColored]="step === 'negative_evaluation_explanation' || step === 'negative_evaluation'"
      [disabled]="isAnswered"
      (click)="onNegativeEvaluation()"
      data-testid="thumb-down"
    >
      <i
        aria-label="-"
        i18n-aria-label="@@icon.thumbs-down"
        class="fof-icon fof-icon-thumbs-down"
      ></i>
    </button>
  </div>

  <article
    [botMessage]="afterPositiveEvaluationBotMessage"
    [id]="positiveAnswerScrollAnchor"
    class="mb-3 me-5"
    *ngIf="step === 'positive_evaluation'"
  ></article>

  <article
    [botMessage]="afterNegativeEvaluationBotMessage"
    class="mb-3 me-5"
    *ngIf="step === 'negative_evaluation' || step === 'negative_evaluation_explanation'"
  ></article>

  <div
    [id]="negativeAnswerScrollAnchor"
    style="padding-left: var(--fof-bot-msg-left-space)"
    *ngIf="step === 'negative_evaluation'"
  >
    <button
      *ngFor="let option of data.furtherEvaluationOptions; first as isFirst"
      class="me-2 mb-2"
      formButton="secondary"
      [requestFocus]="isFirst"
      (click)="onNegativeEvaluationExplanation(option.key, option.label)"
      [attr.data-testid]="'negative-explanation-option' + option.key"
    >
      {{ option.label }}
    </button>
  </div>

  <article
    [userMessage]="furtherEvaluationUserMessage"
    class="mb-3"
    *ngIf="step === 'negative_evaluation_explanation'"
  ></article>

  <article
    [botMessage]="afterFurtherEvaluationBotMessage"
    [id]="furtherEvaluationScrollAnchor"
    class="mb-3 me-5"
    *ngIf="step === 'negative_evaluation_explanation'"
  ></article>
</ng-container>
