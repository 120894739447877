import { BotIncomingMessage, SuggestionsContent, SuggestionsIncomingMessage } from "../connector";
import {
  BotTextMessage,
  ConversationMessage,
  ImageMessage,
  MessageMetadata,
  MessageRelationType,
  SuggestionsMessage,
} from "../../common/message.model";
import { BotIncomingMessageConverter } from "./message-converter.model";

/**
 * Converts a message used to suggestion answers to the user.
 */
export class SuggestionsMessageConverter implements BotIncomingMessageConverter {
  private suggestionsWithText(message: SuggestionsIncomingMessage) {
    return message.content.suggestions.filter((suggestion) => !!suggestion.text);
  }

  supports(incomingMessage: BotIncomingMessage): boolean {
    const hasSuggestions =
      (incomingMessage?.content_type === "text" || incomingMessage?.content_type === "image") &&
      incomingMessage.content?.hasOwnProperty("suggestions") &&
      (incomingMessage.content as SuggestionsContent).suggestions?.length > 0;
    const suggestions = hasSuggestions
      ? this.suggestionsWithText(incomingMessage as SuggestionsIncomingMessage)
      : [];
    return suggestions.length > 0;
  }
  convert(incomingMessage: BotIncomingMessage): Array<ConversationMessage> {
    const result = new Array<ConversationMessage>();
    const suggestionsMessage = incomingMessage as SuggestionsIncomingMessage;
    let nextMessageRelationType: MessageRelationType = "none";
    if (suggestionsMessage.content.value) {
      result.push(BotTextMessage.makeIntroducerMessage(suggestionsMessage.content.value));
      nextMessageRelationType = "introduced";
    }
    if (suggestionsMessage.content.url) {
      result.push(
        new ImageMessage(
          suggestionsMessage.content.url,
          suggestionsMessage.content.alt || "image",
          new MessageMetadata(suggestionsMessage.content.animation, "introducer", "end")
        )
      );
      nextMessageRelationType = "introduced";
    }
    result.push(
      new SuggestionsMessage(
        this.suggestionsWithText(suggestionsMessage),
        new MessageMetadata(
          suggestionsMessage.content.animation,
          nextMessageRelationType,
          "default"
        )
      )
    );
    return result;
  }
}
