import { BotIncomingMessage, CarouselItemIncoming, CarouselIncomingMessage } from "../connector";
import {
  CarouselItem,
  CarouselMessage,
  ConversationMessage,
  MessageMetadata,
  UrlRedirect,
} from "../../common/message.model";
import { BotIncomingMessageConverter } from "./message-converter.model";

/**
 * Converts a bot carousel message.
 */
export class CarouselMessageConverter implements BotIncomingMessageConverter {
  supports(incomingMessage: BotIncomingMessage): boolean {
    return incomingMessage?.content_type === "carousel";
  }
  convert(incomingMessage: BotIncomingMessage): Array<ConversationMessage> {
    const result = new Array<ConversationMessage>();
    const incomingCarouselMessage = incomingMessage as CarouselIncomingMessage;

    result.push(
      new CarouselMessage(
        this.adaptIncomingToCarouselItems(incomingCarouselMessage.content.items),
        new MessageMetadata("", "none", "default")
      )
    );
    return result;
  }

  private adaptIncomingToCarouselItems(
    incomingItems: Array<CarouselItemIncoming>
  ): Array<CarouselItem> {
    return incomingItems.map((incomingItem) => {
      if (incomingItem.action) {
        if (incomingItem.action.external_url) {
          return new CarouselItem(
            incomingItem.image_url ?? "",
            incomingItem.image_alt ?? "image",
            incomingItem.title ?? "",
            incomingItem.description ?? undefined,
            new UrlRedirect(incomingItem.action.text ?? "", incomingItem.action.external_url)
          );
        } else if (incomingItem.action.intent) {
          if (incomingItem.action.text) {
            return new CarouselItem(
              incomingItem.image_url ?? "",
              incomingItem.image_alt ?? "image",
              incomingItem.title ?? "",
              incomingItem.description ?? undefined,
              {
                text: incomingItem.action.text ?? "",
                color: incomingItem.action.color ?? "",
                intent: incomingItem.action.intent,
              }
            );
          }
        }
      }
      return new CarouselItem(
        incomingItem.image_url ?? "",
        incomingItem.image_alt ?? "image",
        incomingItem.title ?? "",
        incomingItem.description ?? undefined,
        undefined
      );
    });
  }
}
