<ng-container *ngIf="hasBubble && showBubble && !host.isMobile">
  <div
    class="d-flex align-items-center text-primary border rounded-pill py-1 ps-3 pe-2 mt-1 mb-2 fw-bold"
    style="background-color: var(--bs-body-bg)"
  >
    <span i18n="@@reduced-bot.text" data-testid="bubble">-</span>
    <button
      style="--fof-icon-button-size: var(--fof-reduced-bot-close-bubble-button-size)"
      class="ms-2"
      data-testid="close-bubble"
      type="button"
      icon="close-reduced"
      (click)="closeBubble($event)"
      title="-"
      i18n-title="@@reduced-bot.close-bubble.hint"
    ></button>
  </div>
</ng-container>

<img
  class="mw-100 mh-100"
  role="button"
  data-testid="open-chat-button"
  tabindex="0"
  style="width: var(--fof-reduced-bot-avatar-width)"
  (click)="openChat()"
  [src]="src"
  alt="-"
  i18n-alt="@@reduced-bot.logo"
  title="-"
  i18n-title="@@reduced-bot.open.hint"
/>
